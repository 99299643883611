import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'
import { MDBContainer, MDBRow, MDBCol, MDBTabPane, MDBTabContent, MDBNav, MDBNavItem, } from 'mdbreact'
import Layout from '../components/layout'
import Hero from '../components/heroSubpage'
import SEO from '../components/seo'
import ProductsByCategory from '../components/productsByCategory'
import ProductsByAZ from '../components/productsByAZ'
import get from 'lodash/get'

class TabsDefault extends React.Component {
  state = {
    activeItem: '1',
  }

  toggle = tab => e => {
    if (this.state.activeItem !== tab) {
      this.setState({
        activeItem: tab,
      })
    }
  }

  render() {
    const az = get(this, 'props.data.az.edges')
    const categories = get(this, 'props.data.categories.edges')
    const heroImage = get(this, 'props.data.heroImage')
    return (
      <div id="sub-page">
      <Layout>
        <SEO
          title="Products by A-Z and product capabilities"
          description="Macro 4 software products listed out alphabetically (A-Z) and also by product capabilities that specific products are designed to provide"
          url="https://www.macro4.com/products/"
          image={"https://www.macro4.com" + heroImage.childImageSharp?.gatsbyImageData?.images?.fallback?.src}
        />

        <Hero
          class="intro-65"
          image={heroImage.childImageSharp.gatsbyImageData}
          foreimage={null}
          forealt=""
          title="Products"
          subtitle="Your vision. Our technology."
          ctatext="Let's talk"
          ctaslug="/contact/"          
          type="root"
        />
        <section className="bg-white">
          <MDBContainer>
            <MDBRow>
              <MDBCol>
              <p className="font-w-400 text-xs-extra-large divlink text-opening-para">Reach your goals faster with our software solutions.</p>
                <p className="text-medium mb-5 mt-3">                  
                  Browse our product capabilities to learn how we can help. Or consult our product A-Z.
                </p>
                <p>
                  Take a look at our <Link to="/solutions/" className="effect">solution pages</Link> for additional information and business use cases.
                </p>
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <MDBCol>
                <MDBNav className="nav-tabs mt-5 mb-0">
                  <MDBNavItem className="nav-item-border">
                    <a
                      className={
                        this.state.activeItem === '1'
                          ? 'nav-link active text-xs-small text-medium-small'
                          : 'nav-link text-xs-small text-medium-small'
                      }
                      onClick={this.toggle('1')}
                      role="tab"
                    >
                      A-Z
                    </a>
                  </MDBNavItem>
                  <MDBNavItem className="nav-item-border">
                    <a
                      className={
                        this.state.activeItem === '3'
                          ? 'nav-link active text-xs-small text-medium-small'
                          : 'nav-link text-xs-small text-medium-small'
                      }
                      onClick={this.toggle('3')}
                      role="tab"
                    >
                      Product capabilities
                    </a>
                  </MDBNavItem>
                </MDBNav>
                <MDBTabContent activeItem={this.state.activeItem}>
                  <MDBTabPane tabId="1" role="tabpanel">
                    <div className="intro-content">
                      <ProductsByAZ data={az} />
                    </div>
                  </MDBTabPane>
                  <MDBTabPane tabId="3" role="tabpanel">
                    <div className="intro-content">
                      <ProductsByCategory data={categories} />
                    </div>
                  </MDBTabPane>
                </MDBTabContent>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </section>
      </Layout>
      </div>      
    )
  }
}
export default TabsDefault

export const query = graphql`
  query {
    az: allMarkdownRemark(
      sort: { order: ASC, fields: frontmatter___title }
      filter: { frontmatter: { template: { eq: "products" }, hide: {ne: true} } }
    ) {
      edges {
        node {
          frontmatter {
            title
            subtitle
          }
          fields {
            slug
          }
        }
      }
    }
    categories: allMarkdownRemark(
      sort: { order: ASC, fields: frontmatter___categoryname }
      filter: { frontmatter: { categoryname: { ne: null } } }
    ) {
      edges {
        node {
          frontmatter {
            categoryname
            categorydescription
            related {
              link
              name
            }
          }
        }
      }
    }
    heroImage: file(name: { eq: "product-banner" }) {
      childImageSharp {
        gatsbyImageData(quality: 90) 
        }
      }
    }
`
